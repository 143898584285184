@import "./less.less";
@import "../defaults/css.less";

:global {
	:root {
		--color-picker-neutral-1: #fff;
		--color-picker-neutral-2: #f2f1f0;
		--color-picker-neutral-3: #ece6e4;
		--color-picker-neutral-4: #999;
		--color-picker-neutral-5: #686868;
		--color-picker-neutral-6: #000;
		--color-picker-neutral-slider: var(--color-picker-neutral-1);

		--color-picker-red-1: #ff9c9c;
		--color-picker-red-2: #ff5353;
		--color-picker-red-3: #e81111;
		--color-picker-red-4: #d30000;
		--color-picker-red-5: #b10101;
		--color-picker-red-6: #740000;
		--color-picker-red-slider: var(--color-picker-red-3);

		--color-picker-orange-1: #f7c281;
		--color-picker-orange-2: #ec9d60;
		--color-picker-orange-3: #f98e0c;
		--color-picker-orange-4: #f85e00;
		--color-picker-orange-5: #e2711d;
		--color-picker-orange-6: #cc5803;
		--color-picker-orange-slider: var(--color-picker-orange-3);

		--color-picker-yellow-1: #faeb8a;
		--color-picker-yellow-2: #faf47b;
		--color-picker-yellow-3: #fff200;
		--color-picker-yellow-4: #ffd800;
		--color-picker-yellow-5: #ffba00;
		--color-picker-yellow-6: #d89b00;
		--color-picker-yellow-slider: var(--color-picker-yellow-3);

		--color-picker-green-1: #a9f858;
		--color-picker-green-2: #68ff74;
		--color-picker-green-3: #00d10e;
		--color-picker-green-4: #77ad3e;
		--color-picker-green-5: #008d0a;
		--color-picker-green-6: #33550e;
		--color-picker-green-slider: var(--color-picker-green-3);

		--color-picker-turquoise-1: #b8e9ed;
		--color-picker-turquoise-2: #7adce4;
		--color-picker-turquoise-3: #00c4d4;
		--color-picker-turquoise-4: #00868f;
		--color-picker-turquoise-5: #006066;
		--color-picker-turquoise-6: #003f43;
		--color-picker-turquoise-slider: var(--color-picker-turquoise-3);

		--color-picker-blue-1: #d3e4fa;
		--color-picker-blue-2: #b2d4ff;
		--color-picker-blue-3: #89b7f3;
		--color-picker-blue-4: #0065e6;
		--color-picker-blue-5: #003982;
		--color-picker-blue-6: #00295d;
		--color-picker-blue-slider: var(--color-picker-blue-4);

		--color-picker-purple-1: #e4c0ff;
		--color-picker-purple-2: #fbaaf5;
		--color-picker-purple-3: #ff5df4;
		--color-picker-purple-4: #d06dc9;
		--color-picker-purple-5: #c475ff;
		--color-picker-purple-6: #9c1aff;
		--color-picker-purple-slider: var(--color-picker-purple-6);

		--color-picker-pink-1: #ffbdc9;
		--color-picker-pink-2: #f6b3ab;
		--color-picker-pink-3: #f38375;
		--color-picker-pink-4: #e45644;
		--color-picker-pink-5: #ff6884;
		--color-picker-pink-6: #e30564;
		--color-picker-pink-slider: var(--color-picker-pink-5);

		--font-family-regular: @fontFamilyRegular;
		--font-family-demi-bold: @fontFamilyDemiBold;
		--font-family-bold: @fontFamilyBold;
		--font-weight-regular: @fontWeightRegular;
		--font-weight-demi-bold: @fontWeightDemiBold;
		--font-weight-bold: @fontWeightBold;

		--font-transform: @fontTransform;

		.light {
			// Background
			--background-primary: #fff;
			--background-primary-inverse: #333;
			--background-primary-translucent: rgba(#fff, 0.9);
			--background-secondary: #F5F5F5;
			--background-tertiary: #fff;
			--background-quaternary: #F5F5F5;

			/* HEADER */
			--header-background: #fff;
			--header-pull-background: #fff;
			--header-border-color: #e0e0e0;
			--header-icon-color: #fff;

			// Font
			--font-primary: #323232;
			--font-secondary: #fff;
			--font-on-background-fill: #323232;
			--font-disabled: rgba(#323232, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #323232;
			--font-neutral-grey1: #8F8F8F;

			// Icon
			--icon-default: #323232;
			--icon-active: #fff;
			--icon-disabled: rgba(#323232, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #323232;

			// Neutrals
			--white1: #fff;
			--white2-disabled: rgba(#fff, 0.3);
			--black1: #323232;
			--black2-disabled: rgba(#323232, 0.3);
			--neutral1-borders-and-stroke: #DFDFDF;
			--neutral2-separator-and-divider: rgba(#333333, 0.1);
			--neutral3-background-fill: #F5F5F5;
			--neutral4-grey: #8F8F8F;
			--neutral5-slider-step: #E4E4E4;
			--neutral6-grey-fill: #D9D9D9;

			// Colors
			--menu-selection: #ED4141;
			--primary1: #00838C;
			--primary1-disabled: rgba(#00838C, 0.3);
			--primary2: #01565B;
			--primary2-disabled: rgba(#01565B, 0.3);
			--accent1: #F9830C;
			--accent2: #F9CA8C;
			--accent3: #F8C011;
			--accent4: #00868F;
			--alert-info: #0065EC;
			--alert-warning: #F8C011;
			--gridlines: #E81111;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);

			// Select
			--select-background-primary: #fff;
			--select-background-secondary: #fff;
			--select-options-background-primary: #F5F5F5;
			--select-options-background-secondary: #fff;

			// Input Slider
			--input-slider-accent: #65acff;
			--input-slider-background: rgba(#333333, 0.1);
			--input-slider-handle-border: #fff;
		}

		.dark {
			// Background
			--background-primary: #232323;
			--background-primary-inverse: #fff;
			--background-primary-translucent: rgba(#232323, 0.9);
			--background-secondary: #333;
			--background-tertiary: #333;
			--background-quaternary: #232323;

			/* HEADER */
			--header-background: #fff;
			--header-pull-background: #fff;
			--header-border-color: #e0e0e0;
			--header-icon-color: #fff;

			// Font
			--font-primary: #fff;
			--font-secondary: #323232;
			--font-on-background-fill: #323232;
			--font-disabled: rgba(#fff, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #323232;
			--font-neutral-grey1: #8F8F8F;

			// Icon
			--icon-default: #fff;
			--icon-active: #323232;
			--icon-disabled: rgba(#fff, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #323232;

			// Neutrals
			--white1: #fff;
			--white2-disabled: rgba(#fff, 0.3);
			--black1: #323232;
			--black2-disabled: rgba(#323232, 0.3);
			--neutral1-borders-and-stroke: #dfdfdf;
			--neutral2-separator-and-divider: rgba(#dfdfdf, 0.25);
			--neutral3-background-fill: #f5f5f5;
			--neutral4-grey: #8f8f8f;
			--neutral5-slider-step: #e4e4e4;
			--neutral6-grey-fill: #d9d9d9;

			// Colors
			--menu-selection: #ed4141;
			--primary1: #00838c;
			--primary1-disabled: rgba(#00838c, 0.3);
			--primary2: #01565b;
			--primary2-disabled: rgba(#01565b, 0.3);
			--accent1: #f9830c;
			--accent2: #f9ca8c;
			--accent3: #f8c011;
			--accent4: #00868f;
			--alert-info: #0065ec;
			--alert-warning: #f8c011;
			--gridlines: #e81111;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);

			// Select
			--select-background-primary: #333;
			--select-background-secondary: #232323;
			--select-options-background-primary: #333;
			--select-options-background-secondary: #232323;

			// Input Slider
			--input-slider-accent: #65acff;
			--input-slider-background: #f5f5f5;
			--input-slider-handle-border: #f0f0f0;
		}
	}
}
